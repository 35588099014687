import { v4 as uuidv4 } from 'uuid';
import HelperService from '@utils/helper-service';

const attributes = {
  aspectRatio: 'aspect-ratio',
  playDisallowed: 'play-disallowed',
  processingCopy: 'processing-copy',
  errorCopy: 'error-copy',
  videoId: 'video-id',
  playerId: 'player-id',
  token: 'token',
};

export default class JwPlayerEmbed extends HTMLElement {
  #rootElement;

  #videoPlayerWrapper;

  #videoPlayer;

  #scriptElement;

  #videoId;

  #playerId;

  #processingCopy;

  #errorCopy;

  #isWidescreen;

  #videoPlayerId;

  #jwPlayerInstance;

  #token;

  #playDisallowed;

  #initialized = false;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);
    this.#playerId = this.getAttribute(attributes.playerId);
    this.#token = this.getAttribute(attributes.token);

    this.#isWidescreen =
      !this.hasAttribute(attributes.aspectRatio) || this.getAttribute(attributes.aspectRatio) === 1.7777777777777777;
    this.#playDisallowed =
      this.hasAttribute(attributes.playDisallowed) && this.getAttribute(attributes.playDisallowed) !== 'false';

    this.handleVideoRender();
    this.handleVideoProcessing();
    this.handleVideoError();

    this.appendChild(this.#rootElement);
  }

  disconnectedCallback() {
    this.#initialized = false;
    this.#rootElement.remove();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === attributes.playDisallowed && !!this.#jwPlayerInstance) {
      this.handlePlayDisallowed(newValue);
    }
  }

  handleVideoRender() {
    this.#rootElement = document.createElement('div');
    this.#videoPlayerWrapper = document.createElement('div');
    this.#rootElement.appendChild(this.#videoPlayerWrapper);

    this.#videoPlayerWrapper.classList.add('video-player', 'flex-video', 'sjwc-jwplayer');
    this.#videoPlayerWrapper.classList.toggle('widescreen', this.#isWidescreen);

    this.#videoPlayer = document.createElement('div');
    this.#videoPlayer.classList.add('jwplayer');
    this.#videoPlayerWrapper.appendChild(this.#videoPlayer);

    this.updateVideoId(this.#videoId);

    this.handleScript();
  }

  handleVideoProcessing() {
    this.#processingCopy =
      this.getAttribute(attributes.processingCopy) ||
      'This video is still being processed. Please check back later and refresh the page.';

    const processingWrapper = document.createElement('div');
    processingWrapper.style.display = 'none';
    processingWrapper.classList.add('video-processing', 'flex-video');
    processingWrapper.classList.toggle('widescreen', this.#isWidescreen);

    const copyWrapper = document.createElement('div');
    const spinnerIcon = document.createElement('i');
    spinnerIcon.classList.add('fa', 'fa-spinner', 'fa-spin');

    const processingCopy = document.createElement('p');
    processingCopy.textContent = this.#processingCopy;

    copyWrapper.appendChild(spinnerIcon);
    copyWrapper.appendChild(processingCopy);
    processingWrapper.appendChild(copyWrapper);

    this.#rootElement.appendChild(processingWrapper);
  }

  handleVideoError() {
    this.#errorCopy = this.getAttribute(attributes.errorCopy) || 'Uh oh! Something went wrong, please try again.';

    const errorWrapper = document.createElement('div');
    errorWrapper.style.display = 'none';
    errorWrapper.classList.add('video-error', 'flex-video');
    errorWrapper.classList.toggle('widescreen', this.#isWidescreen);

    const errorCopyWrapper = document.createElement('div');
    const errorCopy = document.createElement('p');
    errorCopy.textContent = this.#errorCopy;

    errorCopyWrapper.appendChild(errorCopy);
    errorWrapper.appendChild(errorCopyWrapper);

    this.#rootElement.appendChild(errorWrapper);
  }

  updateVideoId(newVideoId) {
    this.#videoPlayerId = `botr_${newVideoId}_${this.#playerId}_${uuidv4()}_div`;
    this.#videoPlayer.id = this.#videoPlayerId;

    if (newVideoId !== this.#videoId) {
      this.#videoId = newVideoId;
      this.handleScript();
    }
  }

  handleScript() {
    const scriptAttributes = {
      type: 'text/javascript',
      src: `https://content.jwplatform.com/libraries/${this.#playerId}.js`,
      async: true,
    };

    this.#scriptElement = HelperService.loadScript(scriptAttributes, this.#rootElement, this.setupListeners.bind(this));
  }

  setupListeners() {
    let playlistUrl = `//content.jwplatform.com/v2/media/${this.#videoId}`;
    if (this.#token) {
      playlistUrl = `//content.jwplatform.com/v2/media/${this.#videoId}?token=${this.#token}`;
    }

    this.#jwPlayerInstance = jwplayer(this.#videoPlayerId).setup({
      playlist: playlistUrl,
    });

    this.#jwPlayerInstance.on('setupError', (error) => this.handleError(error));
    this.#jwPlayerInstance.on('error', (error) => this.handleError(error));

    if (!this.#initialized) this.handlePlayDisallowed(this.#playDisallowed);

    this.#initialized = true;
  }

  removeListeners() {
    this.#jwPlayerInstance.off('setupError');
    this.#jwPlayerInstance.off('error');
  }

  handleError(error) {
    const hasPlaylist = typeof this.#jwPlayerInstance.getPlaylist() === 'string';

    if (hasPlaylist || this.#jwPlayerInstance.getPlaylist().length === 0) {
      const videoPlayer = this.#rootElement.querySelector('.video-player');
      const editCoverImage = this.#rootElement.querySelector('.edit-cover-image');

      if (videoPlayer) videoPlayer.style.display = 'none';
      if (editCoverImage) editCoverImage.style.display = 'none';

      // 102404 means 404 from jwplayer
      // unfortunately thats also what is returned if a video is still processing, so we are using that here
      // all other error codes should surface a generic error message
      if (error && error.code === 102404) {
        const videoProcessing = this.#rootElement.querySelector('.video-processing');

        if (videoProcessing) {
          videoProcessing.style.display = 'block';
        }
      } else {
        const videoError = this.#rootElement.querySelector('.video-error');

        if (videoError) {
          videoError.style.display = 'block';
        }

        // eslint-disable-next-line no-console
        console.sentryCaptureException(error);
      }
    }
  }

  handlePlayDisallowed(newValue) {
    this.#playDisallowed = newValue;

    this.#jwPlayerInstance.setControls(!this.#playDisallowed);

    const playingWhenNotAllowed = this.#jwPlayerInstance.getState() === 'playing' && this.#playDisallowed;
    if (playingWhenNotAllowed) this.#jwPlayerInstance.pause();
  }
}

customElements.define('sjwc-jwplayer', JwPlayerEmbed);
